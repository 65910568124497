import { HeatmapGraphData, HeatmapGraphDataSeries } from 'features/Graphs/HeatmapGraph';
import { DefaultBenchmarkId, DefaultClusterId } from 'common/constants';
import { BenchmarkId } from 'features/Graphs/TensionGraph';
import { ClusterId } from 'common/types';
import { HeatmapGraphBase } from 'features/Graphs/PptxExportButton';

export const useHeatmapGraphData = (
	graphBase: HeatmapGraphBase,
	clusterIndex: number,
	pdnaBenchmarkIndexes: boolean[],
	odnaBenchmarkIndexes: boolean[],
	pnoBenchmarkIndexes: boolean[],
	onBenchmarkClick: (benchmarkId: BenchmarkId, clusterId: ClusterId) => void,
): HeatmapGraphData => {
	const combinedMode = pnoBenchmarkIndexes.includes(true);

	const pdna = graphBase.pdna;
	const odna = graphBase.odna;

	const checkedPdnaBenchmarks = combinedMode ? pnoBenchmarkIndexes : pdnaBenchmarkIndexes;
	const checkedOdnaBenchmarks = combinedMode ? pnoBenchmarkIndexes : odnaBenchmarkIndexes;
	const benchmarksLength = checkedPdnaBenchmarks.length;

	const data: HeatmapGraphDataSeries[] = [];

	for (let benchmarkIndex = 0; benchmarkIndex < benchmarksLength; benchmarkIndex++) {
		const currentPdnaBenchmark = pdna.clusters[clusterIndex].benchmarks[benchmarkIndex];
		const currentOdnaBenchmark = odna.clusters[clusterIndex].benchmarks[benchmarkIndex];

		const pdnaBenchmarkEnabled = checkedPdnaBenchmarks[benchmarkIndex];
		const odnaBenchmarkEnabled = checkedOdnaBenchmarks[benchmarkIndex];

		const pdnaValueExists = !!currentPdnaBenchmark.left?.numberOfParticipants;
		const odnaValueExists = !!currentOdnaBenchmark.left?.numberOfParticipants;

		const pdnaLabel = pdnaBenchmarkEnabled ? currentPdnaBenchmark.label : '';
		const odnaLabel = odnaBenchmarkEnabled ? currentOdnaBenchmark.label : '';
		const label = pdnaLabel ? pdnaLabel : odnaLabel;

		const left = pdnaBenchmarkEnabled && pdnaValueExists ? currentPdnaBenchmark.left : undefined;
		const right = odnaBenchmarkEnabled && odnaValueExists ? currentOdnaBenchmark.left : undefined;

		if (!left && !right) continue;
		const cluster = Object.values(DefaultClusterId)[clusterIndex];
		const benchmark = Object.values(DefaultBenchmarkId)[benchmarkIndex];
		const onHeaderClick = () => onBenchmarkClick(benchmark, cluster);
		data.push({
			label,
			left,
			onHeaderClick,
			right,
		} as HeatmapGraphDataSeries);
	}

	const categoryLabelsClusters =
		pdna.clusters[clusterIndex].labels.left.length > 0
			? [...pdna.clusters[clusterIndex].labels.left]
			: [...odna.clusters[clusterIndex].labels.left];

	return {
		categoryLabels: data.length ? categoryLabelsClusters : [],
		categoryLabelsTitle: 'CultureScope Behaviours',
		clusterName: pdna.clusters[clusterIndex].clusterName,
		data,
	};
};
